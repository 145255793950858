<template>
    <div class="mx-auto container container--fluid">
        <v-app id="inspire" class>
            <v-snackbar v-model="snackbar" :top=true :color="color" :timeout=6000>
                <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
                <v-btn dark text @click="snackbar = false">Close</v-btn>
            </v-snackbar>
            <template v-if="load==true">
                <v-data-table :headers="headers" :items="tpo_list" sort-by="employee_code" class="elevation-1" :search="search">
                    <template v-slot:top>
                        <v-toolbar flat color="white">
                            <v-toolbar-title>TPO</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
                            <span style="width: 20px"></span>
                            <v-dialog v-model="dialog" max-width="500px">
                                <template v-slot:activator="{ on }">
                                    <v-btn color="indigo darken-4" dark class="mb-2" v-on="on">New TPO</v-btn>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        <span class="headline">{{ formTitle }}</span>
                                    </v-card-title>

                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                    <v-col cols="12" sm="12" md="12">
                                                        <v-text-field v-model="editedItem.tponame" :readonly="editedIndex==-1?false:true" :filled="editedIndex==-1?false:true" persistent-hint  label="Enter TPO Name *" :rules="[v => !!v || 'required']">
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" >
                                                        <v-autocomplete v-model="editedItem.employee_code" :items="inst_list"  dense chips small-chips label="Select TPO Cordinator *" ></v-autocomplete>
                                                    </v-col>
                                                    <!-- <v-col cols="12" v-if='editedIndex != -1'>TPO
                                                        <v-select dense v-model="editedItem.employee_code" :items="inst_list" item-value="id" persistent-hint :readonly="editedIndex==-1?false:true" :error="errorMsg.employee_code?true:false" :error-messages="errorMsg.employee_code?'Required':''"></v-select>
                                                    </v-col> -->
                                                     <v-col cols="12" sm="12" md="12">
                                                        <v-text-field v-model="editedItem.tpo_official_contact" counter="10" persistent-hint  label="Enter Official Contact *" :rules="[v => !!v || 'required']">
                                                        </v-text-field>
                                                    </v-col>
                                                     <v-col cols="12" sm="12" md="12">
                                                        <v-text-field v-model="editedItem.tpo_official_emailid" persistent-hint  label="Enter TPO Official Email *" :rules2="[v => !!v || 'required']" :rules="emailRules">
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col v-if="editedIndex==-1" cols="12" >
                                                        <v-autocomplete v-model="editedItem.organization"   :items="org_list"  item-text="name" item-value="id" dense chips small-chips label="Select organization *" multiple></v-autocomplete>                                                  
                                                        </v-col>
                                                        <v-col v-else cols="12" >                                                      
                                                         <v-text-field v-model="editedItem.organization" :readonly="editedIndex==-1?false:true" :filled="editedIndex==-1?false:true" persistent-hint  label="Organization *" :rules="[v => !!v || 'required']">
                                                        </v-text-field>                                                  
                                                    </v-col>
                                                   <!-- <v-col cols="12" v-if='editedIndex != -1'>Organization
                                                         <v-select dense v-model="editedItem.organization" :items="org_list" item-value="id" persistent-hint  :error="errorMsg.organization?true:false" :error-messages="errorMsg.organization?'Required':''"></v-select>
                                                         <v-autocomplete v-model="editedItem.organization" :items="org_list"  dense chips small-chips multiple></v-autocomplete>
                                                    </v-col> -->
                                            </v-row>
                                            <v-row dense>
                                                <v-col>
                                                    <input type="hidden" v-model="editedItem.id" name="id" />
                                                    <!-- <v-hidden v-model="editedItem.id" label="id"></v-hidden> -->
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                                        <v-btn color="primary darken-1" @click="save" :disabled="valid">Save</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>

                      <template v-slot:item.action="{ item }">
                        <v-tooltip left>
                            <template v-slot:activator="{ on }">
                                <v-avatar style size="20" >
                                    <v-icon style size="20" color="error" @click="deleteItem(item)">mdi-delete-empty</v-icon>
                                </v-avatar>
                            </template>
                            <span>edit</span>
                        </v-tooltip>
                    </template> 
                    <template v-slot:item.actions="{ item }">
                        <v-tooltip left>
                            <template v-slot:activator="{ on }">
                               <v-avatar style size="20" class="edit-avatar">
                                    <v-icon  class="edit-v-icon mr-2" @click="editItem(item)" title="edit" style>edit</v-icon>
                                </v-avatar>
                            </template>
                            <span>edit</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </template>
        </v-app>
    </div>
</template>

<script>
    import axios from "axios";
    export default {
        data: () => ({
            valid: false,
            errorMsg: {
                employee_code: false,
                organization: false
            },
            snackbar_msg: "",
            color: "",
            snackbar: false,
            search: "",
            load: false,
            dialog: false,
            tpo_list: null,
            inst_list: null,
            org_list: null,
            headers: [
            {
                text : "TPO Name",
                value: "tponame"
            },
            {
                text: "TPO Cordinator",
                value: "employee_code"
            }, 
            {
                text  : "Official Contact",
                value: "tpo_official_contact"
            },
            {
                text :"Official Email",
                value :"tpo_official_emailid",
            },
            {
                text: "Organization",
                value: "organization"
            },  
            {
                text: "Edit",
                value: "actions",
                sortable: false
            },
            {
                text: "Delete",
                value: "action",
                sortable: false
            }
            ],
            emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],

            editedIndex: -1,
            editedItem: {
                id: '',
                tponame:"",
                inst_list:"",
                tpo_official_contact:"",
                tpo_official_emailid:"",
                employee_code: "",
                organization: "",
            },
            defaultItem: {
                id: '',
                tponame:"",
                inst_list:"",
                tpo_official_contact:"",
                tpo_official_emailid:"",
                employee_code: "",
                organization: "",
           }
        }),

        computed: {
            formTitle() {
                return this.editedIndex === -1 ? "New TPO" : "Edit TPO";
            }
        },

        watch: {
            dialog(val) {
                val || this.close();
            }
        },
        mounted() {
          this.onload() 
        },

        methods: {
            onload(){
                 axios
                .post("/AddTpo/addTpo")
                .then(res => {
                    if (res.data.msg == "200") {
                        this.tpo_list = res.data.tpo_list;
                        this.inst_list = res.data.inst_list;
                        this.org_list = res.data.org_list;
                        this.load = true;
                    } else {}
                })
                .catch(error => {
                    window.console.log(error);
                });
            },
            initializeErrorMsgFalse() {
                    this.errorMsg.employee_code = false;
                    this.errorMsg.organization = false;
                },
                showSnackbar(clr, msg) {
                    this.snackbar = true;
                    this.color = clr;
                    this.snackbar_msg = msg;
                },
                editItem(item) {
                    this.editedIndex = this.tpo_list.indexOf(item);
                    this.editedItem = Object.assign({}, item);
                    this.dialog = true;
                },

                close() {
                    this.dialog = false;
                    this.initializeErrorMsgFalse();
                    setTimeout(() => {
                        this.editedItem = Object.assign({}, this.defaultItem);
                        this.editedIndex = -1;
                    }, 300);
                },

                save() {
                      if (this.editedItem.tponame == "") {
                        this.showSnackbar("red", "please Enter TPO Name");
                    } 
                    else if(this.editedItem.employee_code == "")
                    {
                        this.showSnackbar("red", "please Select TPO Coordinator");

                    }
                    else if(this.editedItem.tpo_official_contact == "")
                    {
                        this.showSnackbar("red", "please Enter Official Contact Number");

                    }
                    else if(this.editedItem.tpo_official_emailid == "")
                    {
                        this.showSnackbar("red", "Please Enter Official Email Id");

                    }
                    else if(this.editedItem.organization == "")
                    {
                        this.showSnackbar("red", "please Select Organization");

                    }
                    else{
                        if (this.editedIndex > -1) {
                            if (this.isValidated()) {
                                //  console.log("edit.." + this.editedItem);
                                
                                axios
                                    .post("/AddTpo/editTpo", this.editedItem)
                                    .then(res => {
                                        if (res.data.msg == "200") {
                                            Object.assign(
                                                this.tpo_list[this.editedIndex],
                                                this.editedItem
                                            );
                                            this.showSnackbar(
                                                "#4caf50",
                                                "TPO Updated Successfully..."
                                            ); // show snackbar on success
                                        }
                                    })
                                    .catch(error => {
                                        window.console.log(error);
                                    });

                                this.close();
                            }
                        } else {
                            if (this.isValidated()) {
                                //console.log(this.editedItem);
                                axios
                                    .post("/AddTpo/saveTpo", this.editedItem)
                                    .then(res => {
                                        if (res.data.status.code == "SUCCESS") {
                                            // this.tpo_list.push(this.editedItem);
                                            this.onload()
                                            this.showSnackbar(
                                                "#4caf50",
                                                "TPO Added Successfully..."
                                            ); // show snackbar on success
                                        } else if (res.data.status.code == "NA") {
                                            this.showSnackbar(
                                                "#b71c1c",
                                                " Organization Allready Allocated The TPO!!!"
                                            ); // show snackbar on error
                                        }
                                    })
                                    .catch(error => {
                                        window.console.log(error);
                                    });

                                this.close();
                            }
                         
                         }
                    }     
                }, // end of save()


                deleteItem(item) {
                var r = confirm("Are you sure you want to delete this item?");
                if (r) {
                    const tpoId = {
                    id: item.id,
                    organization:item.organization
                    };
                    //console.log(tpoId)
                    axios
                    .post("/AddTpo/deleteTpo", tpoId)
                    .then(res => {
                        if (res.data.msg == "200") {
                        this.showSnackbar("#4CAF50", "TPO deleted...");
                        // const index = this.tpo_list.indexOf(item);
                        // this.tpo_list.splice(index, 1);
                        this.onload()
                        // } else {
                        // this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        // }
                        }
                    })
                    .catch(error => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    })
                    .finally(() => {});
                } else {}
                },

                isValidated() {
                    if (this.editedItem.employee_code && this.editedItem.organization) {
                        return true;
                    } else {
                        if (!this.editedItem.employee_code) {
                            this.errorMsg.employee_code = true;
                        }
                        if (!this.editedItem.organization) {
                            this.errorMsg.organization = true;
                        }
                        return false;
                    }
                }
        }
    };
</script>
<style scoped>
    .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
    }
    
    .v-icon-size {
        font-size: 20px;
    }
    
    .edit-avatar {
        transition: 0.3s;
        border: 1px solid #b0bec5;
    }
    
    .edit-avatar:hover {
        background-color: #b0bec5;
    }
    
    .edit-v-icon {
        padding-left: 6px;
        font-size: 15px;
    }
    
    .edit-v-icon:hover {
        color: white;
    }
</style>